export const UI_REDUCER_INITIAL_STATE = {
  processing: null,
  success: null,
  error: null,
  isMobileMenuOpen: null,
};

export const UI_REDUCER_ACTIONS = {
  SET_PROCESSING: 'SET_PROCESSING',
  SITE_HEADER_MOBILE_MENU: 'SITE_HEADER_MOBILE_MENU',
  RESET: 'RESET',
};
