import React, { createContext, useContext, useReducer } from 'react';

import { UI_REDUCER_ACTIONS, UI_REDUCER_INITIAL_STATE } from '@/utils/reducers';

export const UIContext = createContext();

export function UIProvider({ children }) {
  const [UIState, UIDispatch] = useReducer(reducer, UI_REDUCER_INITIAL_STATE);

  return (
    <UIContext.Provider
      value={{
        UIState,
        UIDispatch,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

export const useUI = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider');
  }
  return context;
};

export function reducer(state, action) {
  switch (action.type) {
    case UI_REDUCER_ACTIONS.SET_PROCESSING:
      return {
        ...state,
        processing: true,
        success: false,
        error: false,
      };

    case UI_REDUCER_ACTIONS.SET_SUCCESS:
      return {
        ...state,
        processing: false,
        success: true,
        error: false,
      };

    case UI_REDUCER_ACTIONS.SET_FAILURE:
      return {
        ...state,
        processing: false,
        success: false,
        error: state.error ?? false,
      };

    case UI_REDUCER_ACTIONS.ERROR:
      return {
        ...state,
        processing: false,
        success: false,
        error: state.error ?? false,
      };

    case UI_REDUCER_ACTIONS.SITE_HEADER_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuOpen:
          action.isMobileMenuOpen !== undefined
            ? action.isMobileMenuOpen
            : state.isMobileMenuOpen,
      };

    case UI_REDUCER_ACTIONS.RESET:
      return {
        ...UI_REDUCER_INITIAL_STATE,
      };

    default:
      return UI_REDUCER_INITIAL_STATE;
  }
}
